import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import { OrderStatus, PaymentMethods } from '../types/order';
import { Helpers } from '../helpers';
import OrdersTableFilter from './orders/OrdersFilter';




const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '10%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/orders/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: 'Url',
        dataIndex: 'serial',
        width: '10%',
        render: (text: string, item: any) => {
            return (<a target="_blank" rel="noopener noreferrer" href={`https://noiroconcept.com/orders/${text}`}>Order #{item._id}</a>)
        }
    },
    {
        title: 'Customer',
        dataIndex: 'address',
        width: '20%',
        render: (address: any, item: any) => {
            return (<p>{address.firstName} {address.lastName}</p>)
        }
    },
    {
        title: 'Total',
        dataIndex: 'payment',
        render: (payment: any, item: any) => {
            return (<p>${payment.grandTotal}</p>)
        }
    },
    {
        title: 'Payment Status',
        dataIndex: 'payment',
        key: "paymentStatus",
        render: (payment: any, item: any) => {
            return (<p>{payment.status}</p>)
        }
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment',
        key: "paymentMethod",
        render: (payment: any, item: any) => {
            return (<p>{PaymentMethods.format(payment.method)}</p>)
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (status: any, item: any) => {
            return (<p>{OrderStatus.format(status)}</p>)
        }
    },
    {
        title: 'Ordered On',
        dataIndex: 'createdAt',
        render: (createdAt: any, item: any) => {
            return (<p>{Helpers.formatDateFull(createdAt)}</p>)
        }
    }
];

const OrderTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <Table
                tableId={"ordersTable"}
                deleteApiCall={Api.orders.delete}
                allowDelete={false}
                restoreApiCall={Api.orders.restore}
                actions={(record: any) => (
                    <Link to={`/orders/update/${record._id}`}>View</Link>
                )}
                Filter={OrdersTableFilter}
                apiCall={Api.orders.table} columns={columns} />
        </Space>
    )
};

export default OrderTable;