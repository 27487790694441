import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, List, Modal, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Api } from '../../api';
import { ImagesHelpers } from '../../helpers/images';
import { OrderStatus } from '../../types/order';

export const PaymentMethods = {
    cashOnDelivery: "CASH_ON_DELIVERY",
    creditCard: "CREDIT_CARD",
    format(v: string) {
        switch (v) {
            case "CASH_ON_DELIVERY":
                return "Cash On Delivery";
            case "CREDIT_CARD":
                return "Credit Card";
        }
    }
}

const OrderSinglePage = () => {
    let { id }: { id?: string } = useParams();
    const [state, setState] = useState({ loading: false, order: { status: "", _id: "" } });
    const fetchOrder = () => {
        setState({ ...state, loading: true });
        Api.orders.single(parseInt(id || "0")).then((res) => {
            setState({ order: res.data.data, loading: false });

        });
    };
    useEffect(() => {
        fetchOrder();
    }, []);
    const order: any = state.order;
    if (state.loading || !order._id)
        return (<Spin />)

    console.log(order);
    return (
        <Space style={{ width: "100%" }} direction="vertical">
            <div>
                <Typography.Title level={3} mark style={{ padding: 5 }}> Order#{state.order._id} |  {OrderStatus.format(state.order.status)}</Typography.Title>
            </div>

            <Row gutter={24} style={{ width: "100%", }}>
                <Col style={{ background: "white", padding: 16 }} span={18}>
                    <Space direction="vertical" style={{ width: "100%" }}>

                        <div >
                            <Typography.Title level={4} >Actions</Typography.Title>

                            <Space>
                                {
                                    order.status !== OrderStatus.canceled &&
                                    <Button onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure you want to cancel this order ? ',
                                            icon: <ExclamationCircleOutlined />,
                                            content: 'this action cannot be undone',
                                            okText: 'Yes',
                                            cancelText: 'NO',
                                            onOk: () => {
                                                return new Promise((resolve, reject) => {
                                                    Api.orders.cancel(order._id).then((res) => {

                                                        resolve(res);
                                                        fetchOrder();
                                                    }).catch((err) => {
                                                        console.error(err);
                                                        reject(err);
                                                    })
                                                });
                                            }
                                        });
                                    }} type="danger">Cancel Order</Button>
                                }
                                {
                                    order.status === OrderStatus.awaitingShipping &&
                                    <Button onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure you want to mark this order as shipped ? ',
                                            icon: <ExclamationCircleOutlined />,
                                            content: 'are you sure ?',
                                            okText: 'Yes',
                                            cancelText: 'NO',
                                            onOk: () => {
                                                return new Promise((resolve, reject) => {
                                                    Api.orders.setStatus(order._id, OrderStatus.shipped).then((res) => {

                                                        resolve(res);
                                                        fetchOrder();
                                                    }).catch((err) => {
                                                        console.error(err);
                                                        reject(err);
                                                    })
                                                });
                                            }
                                        });
                                    }} type="primary" >Ship Order</Button>
                                }

                                {
                                    order.status === OrderStatus.shipped &&
                                    <Button onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure you want to mark this order as completed ? ',
                                            icon: <ExclamationCircleOutlined />,
                                            content: 'are you sure ?',
                                            okText: 'Yes',
                                            cancelText: 'NO',
                                            onOk: () => {
                                                return new Promise((resolve, reject) => {
                                                    Api.orders.setStatus(order._id, OrderStatus.completed).then((res) => {

                                                        resolve(res);
                                                        fetchOrder();
                                                    }).catch((err) => {
                                                        console.error(err);
                                                        reject(err);
                                                    })
                                                });
                                            }
                                        });
                                    }} type="primary" >Completed</Button>
                                }
                            </Space>

                        </div>
                        <div>
                            <Typography.Title level={4} >Items</Typography.Title>
                            <List

                                bordered
                                dataSource={order.items}
                                renderItem={(item: any) => (
                                    <List.Item>
                                        <Row gutter={24} style={{ width: "100%" }}>
                                            <Col span={3} style={{ width: "100%" }}>
                                                <div style={{ width: "100%", paddingTop: "100%", position: "relative" }}>
                                                    <img style={{ width: "100%", height: "100%", objectFit: "cover", position: "absolute", left: 0, top: 0 }} alt={item.itemVariation.item.name} src={ImagesHelpers.formatAmazonImage(item.itemVariation.item.images[0])} />
                                                </div>

                                            </Col>
                                            <Col span={6}>
                                                <Typography.Text strong> {item.itemVariation.item.name}</Typography.Text>
                                                {item.itemVariation.options.map((op: any) => (
                                                    <div key={op._id}> {op.option.label}: {op.value} {console.log(op)} </div>
                                                ))}

                                            </Col>
                                            <Col push={10} span={6}>
                                                <Descriptions column={1} size="small">
                                                    <Descriptions.Item label="Price">  ${item.price}</Descriptions.Item>
                                                    <Descriptions.Item label="Quantity">  ${item.quantity}</Descriptions.Item>
                                                    <Descriptions.Item label="Total">  ${item.quantity * item.price}</Descriptions.Item>
                                                </Descriptions>

                                            </Col>

                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </div>
                        <div>
                            <Descriptions column={1} bordered title="Payment">
                                <Descriptions.Item label={"Method"}>{PaymentMethods.format(order.payment.method)}</Descriptions.Item>
                                <Descriptions.Item label={"Status"}>{order.payment.status}</Descriptions.Item>
                                <Descriptions.Item label={"Items Total"}>${order.payment.total}</Descriptions.Item>
                                <Descriptions.Item label={"Shipping Fees"}>${order.payment.shippingFees}</Descriptions.Item>
                                <Descriptions.Item label={"Grand Total"}>${order.payment.grandTotal}</Descriptions.Item>

                            </Descriptions>
                        </div>
                    </Space>
                </Col>
                <Col span={6} >
                    <Space direction="vertical" size="small">
                        <div style={{ background: "white", padding: 25 }}>
                            <Typography.Text strong style={{ fontSize: 17, }} >Customer</Typography.Text>
                            <Descriptions size="small" column={1} style={{ marginTop: 5 }}>
                                <Descriptions.Item label="Name">{order.address.firstName} {order.address.lastName}</Descriptions.Item>
                                <Descriptions.Item label="Email"><a target="_blank" rel="noopener noreferrer" href={`mailto:${order.email}`}>{order.email}</a></Descriptions.Item>
                                <Descriptions.Item label="Phone"><a target="_blank" rel="noopener noreferrer" href={`tel:${order.phone}`}>{order.phone}</a></Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div style={{ background: "white", padding: 25 }}>
                            <Typography.Text strong style={{ fontSize: 17, marginBottom: 15 }}  >  Shipping Address</Typography.Text>
                            <Descriptions size="small" column={1} style={{ marginTop: 5 }}>
                                <Descriptions.Item label="Country">{order.address.country}</Descriptions.Item>
                                <Descriptions.Item label="City">{order.address.city}</Descriptions.Item>
                                <Descriptions.Item label="Address">{order.address.address}</Descriptions.Item>
                                <Descriptions.Item label="ZIP code">{order.address.postalCode || "-"}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Space>

                </Col>
            </Row>
        </Space>
    )
};


export default OrderSinglePage;