import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
import BasicTableFilter from './BasicTableFilter';
import { ImagesHelpers } from '../helpers/images';
//import { CategoriesFilter } from './CategoriesFilter';




const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '20%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/items/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: 'Image',
        dataIndex: 'images',
        key: "images",
        width: '10%',
        align: 'center' as 'center',
        render: (images: any) => {
            if (images[0])
                return (<img width={50} height={50} style={{ objectFit: "cover" }} alt="item" src={ImagesHelpers.formatAmazonImage(images[0], { width: 50 })} />)
        }
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: "name",
        sorter: true,
        width: '20%',
    },
];

const ItemTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <Table
                tableId={"itemsTable"}
                deleteApiCall={Api.items.delete}
                allowDelete={true}
                restoreApiCall={Api.items.restore}
                actions={(record: any) => (
                    <Link to={`/items/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
                apiCall={Api.items.table} columns={columns} />
        </Space>
    )
};

export default ItemTable;