import React, { useEffect, useCallback, useRef, useState } from "react";
import { Row, Col, Input, Button, Checkbox, Space } from "antd";
import { Controller, useForm } from "react-hook-form";
import { FieldErrorMessage } from "./FieldErrorMessage";

export type ItemRequestTableFilterProp = {
  value: any;
  onChange: (value: any) => void;
  filterDefinitions?: any;
  deletedFilter?: boolean;
};
const default_definitions = [
  {
    key: "_id",
    type: "number",
    label: "ID",
    span: 3,
  },
  {
    key: "firstName",
    type: "search",
    label: "First Name",
  },
  {
    key: "lastName",
    type: "search",
    label: "Last Name",
  },
  {
    key: "email",
    type: "search",
    label: "Email",
  },
  {
    key: "phone",
    type: "search",
    label: "Phone",
  },
];
const ItemRequestTableFilter = ({
  value,
  onChange,
  filterDefinitions,
  deletedFilter = true,
}: ItemRequestTableFilterProp) => {
  const { handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: value });
  const [definitions, setDefinitions] = useState(filterDefinitions || default_definitions);

  useEffect(() => {
    if (deletedFilter)
      setDefinitions([
        ...definitions,
        {
          key: "showDeleted",
          type: "showDeleted",
          excludeFromGenerator: true,
        },
      ]);
  }, [deletedFilter]);
  const clearFilter = () => {
    //@ts-ignore
    reset({ showDeleted: false });
    onChange({
      filter: {},
      definitions,
    });
  };

  const handleChange = useCallback(
    (v: any) => {
      onChange({
        filter: v,
        definitions,
      });
    },
    [onChange]
  );
  // const call = useCallback(() => {
  //     reset(value);
  // }, [value, reset]);
  // useEffect(() => {
  //     call();
  // }, [value, call])
  const watchAllFields = watch("showDeleted");

  useEffect(() => {
    if (watchAllFields !== undefined) {
      handleSubmit(handleChange)();
    }
  }, [watchAllFields]);
  return (
    <div className="white-container" style={{ marginBottom: 12 }}>
      <form onSubmit={handleSubmit(handleChange)}>
        <Space size="large" style={{ width: "100%" }} direction="vertical">
          <Row style={{ width: "100%" }} gutter={12}>
            {definitions
              .filter((def: any) => !def.excludeFromGenerator)
              .map((def: any) => (
                <Col key={def.key} span={def.span || 6}>
                  <div className="">
                    <label className="mr-10" htmlFor={def.key}>
                      {def.label}:{" "}
                    </label>
                    <Controller control={control} as={<Input id={def.key} />} name={def.key} />
                  </div>
                  <FieldErrorMessage
                    style={{ textAlign: "center" }}
                    errors={errors}
                    name={def.key}
                    formatName={def.label}
                  />
                </Col>
              ))}
          </Row>
          <Row justify="end">
            <Space size="small">
              <div className="">
                <label className="mr-10" htmlFor={"showDeleted"}>
                  Show Deleted
                </label>
                <Controller
                  onChange={([e]: any) => e.target.checked}
                  control={control}
                  valueName="checked"
                  as={<Checkbox />}
                  name="showDeleted"
                />
              </div>
              <Button htmlType="submit" size="small" type="dashed">
                Submit
              </Button>
              <Button size="small" onClick={clearFilter} type="danger">
                Reset
              </Button>
            </Space>
          </Row>
        </Space>
      </form>
    </div>
  );
};

export default ItemRequestTableFilter;
