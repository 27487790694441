import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Space } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "./BasicTableFilter";
import ItemRequestTableFilter from "./ItemRequestTableFilter";
import { Helpers } from "../helpers";
import { ImagesHelpers } from "../helpers/images";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",

    key: "_id",
    render: (text: string) => {
      return <Link to={`/itemRequests/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
    render: (item: any) => {
      return (
        <>
          <Link title={item.name} to={`/items/update/${item._id}`}>
            <img
              style={{ width: 80, height: 80, objectFit: "cover" }}
              alt="item"
              src={ImagesHelpers.formatAmazonImage(item.images[0], { width: 150 })}
            />
          </Link>
        </>
      );
    },
  },
  {
    title: "Item Name",
    dataIndex: "item",
    key: "itemName",
    sorter: true,
    render: (item: any) => {
      return (
        <>
          <Link title={item.name} to={`/items/update/${item._id}`}>
            {item.name}
          </Link>
        </>
      );
    },
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: true,
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
    sorter: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    sorter: true,
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    ellipsis: true,
  },
];

const ItemRequestTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        tableId={"itemRequestsTable"}
        deleteApiCall={Api.itemRequests.delete}
        allowDelete={true}
        restoreApiCall={Api.itemRequests.restore}
        actions={(record: any) => <Link to={`/itemRequests/update/${record._id}`}>View</Link>}
        Filter={ItemRequestTableFilter}
        apiCall={Api.itemRequests.table}
        columns={columns}
      />
    </Space>
  );
};

export default ItemRequestTable;
