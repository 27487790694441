import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Row, Col, Input, Button, Checkbox, Space } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { FieldErrorMessage } from './FieldErrorMessage';


export type BasicTableFilterProp = {
    value: any,
    onChange: (value: any) => void,
    filterDefinitions?: any,
    deletedFilter?: boolean,
}
const default_definitions = [
    {
        key: '_id',
        type: 'number',
        label: "ID",
        span: 3,
    },
    {
        key: 'name',
        type: 'search',
        label: "Name"
    },
    {
        key: 'showDeleted',
        type: 'showDeleted',
        excludeFromGenerator: true,
    }
];
const BasicTableFilter = ({ value, onChange, filterDefinitions, deletedFilter = true }: BasicTableFilterProp) => {
    const { handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: value });
    const [definitions, setDefinitions] = useState(filterDefinitions || default_definitions);

    // useEffect(() => {
    //     if (deletedFilter)
    //         setDefinitions([
    //             ...definitions,
    //             {
    //                 key: 'showDeleted',
    //                 type: 'showDeleted',
    //                 excludeFromGenerator: true,
    //             }
    //         ])
    // }, [deletedFilter]);
    const clearFilter = () => {
        //@ts-ignore
        reset({ showDeleted: false });
        onChange({
            filter: {},
            definitions,
        })
    }

    const handleChange = useCallback((v: any) => {
        console.log(v);
        onChange({
            filter: v,
            definitions,
        })
    }, [onChange]);
    // const call = useCallback(() => {
    //     reset(value);
    // }, [value, reset]);
    // useEffect(() => {
    //     call();
    // }, [value, call])
    const watchAllFields = watch("showDeleted");


    useEffect(() => {
        if (watchAllFields !== undefined) {
            handleSubmit(handleChange)();
        }
    }, [watchAllFields]);
    return (
        <div className="white-container" style={{ marginBottom: 12 }}>
            <form onSubmit={handleSubmit(handleChange)}>
                <Space size="large" style={{ width: "100%" }} direction="vertical">
                    <Row style={{ width: "100%" }} gutter={12}>
                        {
                            definitions.filter((def: any) => !def.excludeFromGenerator).map((def: any) => (
                                <Col key={def.key} span={def.span || 6}  >
                                    <div className="">
                                        <label className="mr-10" htmlFor={def.key}>{def.label}: </label>
                                        <Controller
                                            control={control}
                                            as={<Input id={def.key} />}
                                            name={def.key}
                                        />
                                    </div>
                                    <FieldErrorMessage style={{ textAlign: 'center' }} errors={errors} name={def.key} formatName={def.label} />
                                </Col>
                            ))
                        }
                        {/* <Col span={6}  >
                            <div className="custom-form-item">
                                <label className="mr-10" htmlFor={"_id"}>ID: </label>
                                <Controller
                                    control={control}
                                    as={<Input id={"_id"} />}
                                    name="_id"
                                />
                            </div>
                            <FieldErrorMessage style={{ textAlign: 'center' }} errors={errors} name="_id" formatName="ID" />
                        </Col>
                        <Col span={6}  >
                            <div className="custom-form-item">
                                <label className="mr-10" htmlFor={"name"}>Name: </label>
                                <Controller
                                    control={control}
                                    as={<Input id={"name"} />}
                                    name="name"
                                />
                            </div>
                            <FieldErrorMessage style={{ textAlign: 'center' }} errors={errors} name="_id" formatName="ID" />
                        </Col> */}





                    </Row>
                    <Row justify="end">
                        <Space size="small">
                            <div className="">
                                <label className="mr-10" htmlFor={"showDeleted"}>Show Deleted</label>
                                <Controller
                                    control={control}
                                    render={({ onChange, onBlur, value }) => {
                                        return (
                                            <Checkbox
                                                checked={value}
                                                onChange={(e) => {
                                                    onChange(e.target.checked)
                                                }}
                                            />
                                        )
                                    }}
                                    name="showDeleted"
                                />
                            </div>
                            <Button htmlType="submit" size="small" type="dashed">Submit</Button>
                            <Button size="small" onClick={clearFilter} type="danger">Reset</Button>
                        </Space>
                    </Row>
                </Space>
            </form>
        </div >
    )
}

export default BasicTableFilter;